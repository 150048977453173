<template>
    <v-card flat>
        <v-card-title>
            <div class="d-flex align-center w-100">
                <div class="d-flex flex-column">
                    <div class="d-flex align-center">
                        <span class="secondary--text font-weight-bold">Exported applications</span>
                        <v-btn
                            :loading="exportedImagesFetching"
                            @click="$store.dispatch('snapshotStore/fetchExportedImages', { snid: $route.params.snid, query: imageListQuery })"
                            icon>
                            <v-icon>refresh</v-icon>
                        </v-btn>
                    </div>
                    <span v-if="exportedImagesFetching" class="caption text-uppercase">fetching exported application list...</span>
                    <span v-else class="caption text-uppercase">{{ exportedImages.length }} exported applications found</span>
                </div>
            </div>
        </v-card-title>
        <v-card-text>
            <v-skeleton-loader v-if="exportedImagesFetching" class="mx-auto" type="table"></v-skeleton-loader>
            <template v-else-if="exportedImages && exportedImages.length">
                <v-text-field
                    label="Search for an exported application..."
                    autofocus
                    v-model="search"
                    clearable
                    @click:clear="removeSearchQuery"
                    outlined
                    dense>
                    <template slot="append-outer">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon>mdi-filter</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <template v-slot:default>
                                        <v-list-item-action>
                                            <v-checkbox v-model="includeFailed">
                                                <template v-slot:label>
                                                    <span class="secondary--text mx-3">Include failed</span>
                                                </template>
                                            </v-checkbox>
                                        </v-list-item-action>
                                    </template>
                                </v-list-item>
                                <v-list-item>
                                    <template v-slot:default>
                                        <v-list-item-action>
                                            <v-checkbox v-model="includeDeleted">
                                                <template v-slot:label>
                                                    <span class="secondary--text mx-3">Include deleted</span>
                                                </template>
                                            </v-checkbox>
                                        </v-list-item-action>
                                    </template>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-text-field>
                <v-data-table :search="search" item-key="deid" :headers="headers" v-model="selected" :items="exportedImages">
                    <template v-slot:[`item.exit_code`]="{ item }">
                        <v-chip small color="warning" v-if="item.delete_timestamp">Deleted</v-chip>
                        <v-chip small color="primary" v-else-if="!item.end_timestamp">In progress</v-chip>
                        <v-chip small color="success" v-else-if="item.exit_code === 0">Successful</v-chip>
                        <v-chip small color="error" v-else>Failed</v-chip>
                    </template>
                    <template v-slot:[`item.app_name`]="{ item }">
                        <span>{{ item.app_name }}</span>
                    </template>
                    <template v-slot:[`item.app_description`]="{ item }">
                        <span>{{ item.app_description }}</span>
                    </template>

                    <template v-slot:[`item.end_timestamp`]="{ item }">
                        <span v-if="item.end_timestamp">{{ item.end_timestamp | dateTimeToHuman }}</span>
                        <span v-else>-</span>
                    </template>
                    <template v-slot:[`item.docker_url`]="{ item }">
                        <span v-if="item.exit_code === 0">
                            <span>{{ item.docker_url }}</span>
                        </span>
                        <span v-else>-</span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="d-flex flex-rows" v-if="!item.delete_timestamp && item.exit_code === 0">
                            <copy-to-clipboard
                                :textToCopy="item.run_command"
                                buttonClass="caption font-weight-bold"
                                buttonName="copy to clipboard"
                                :iconButton="true"
                                tooltip="Copy run command to clipboard"></copy-to-clipboard>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        small
                                        color="secondary"
                                        @click="openDeleteDialog(item)"
                                        :loading="deletingImage?.deid === item.deid && deleteRequestRunning"
                                        v-bind="attrs"
                                        v-on="on">
                                        <v-icon small>delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete exported application</span>
                            </v-tooltip>
                        </div>
                    </template>
                </v-data-table>
            </template>
            <v-banner v-else single-line>
                <v-avatar slot="icon" color="white" size="60">
                    <v-icon x-large icon="info" color="info">info</v-icon>
                </v-avatar>
                <div class="d-flex flex-column">
                    <span class="font-weight-bold secondary--text">No environments found</span>
                    Currently this space has no exported environments.
                </div>
            </v-banner>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import LogRocket from 'logrocket'
import eventBus from '@/components/EventBus'
const CopyToClipboard = () => import('@/components/CopyToClipboard.vue')

export default {
    components: { CopyToClipboard },
    data() {
        return {
            search: '',
            headers: [
                { text: 'Status', align: 'left', value: 'exit_code' },
                { text: 'Name', align: 'left', value: 'app_name' },
                { text: 'Description', align: 'left', value: 'app_description' },
                { text: 'Export date', align: 'left', value: 'end_timestamp' },
                { text: 'Image tag', align: 'right', value: 'docker_url' },
                { text: 'Actions', align: 'center', value: 'actions' }
            ],
            error: false,
            errorContent: '',
            selected: [],
            deleteDialog: false,
            deletingImage: null,
            deleteRequestRunning: false,
            includeFailed: true,
            includeDeleted: true
        }
    },
    created() {
        this.$store.dispatch('snapshotStore/fetchExportedImages', { snid: this.$route.params.snid, query: this.imageListQuery })
        if (this.$route.query.search) this.search = this.$route.query.search
        eventBus.$on('global-dialog-accept', evt => {
            this.sendDeleteRequest()
        })
    },
    destroyed() {
        eventBus.$off('global-dialog-accept')
    },
    computed: {
        ...mapState('snapshotStore', ['exportedImages', 'exportedImagesFetching']),
        imageListQuery() {
            return `?include_deleted=${this.includeDeleted}&include_failed=${this.includeFailed}`
        },
        imageTag() {
            if (!this.deletingImage) return ''
            return this.deletingImage.docker_url.split(':')[1]
        }
    },
    methods: {
        removeSearchQuery() {
            this.$router.replace({
                ...this.$router.currentRoute,
                query: {
                    search: undefined
                }
            })
        },
        openDeleteDialog(image) {
            this.deletingImage = image
            this.$store.dispatch('showGlobalDialog', {
                dialogText: `Are you sure you would like to delete the exported application <em>${this.imageTag}</em>?`,
                dialogAction: ['delete', 'cancel'],
                dialogTitle: 'Confirm deletion'
            })
        },
        closeDeleteDialog() {
            this.deletingImage = null
        },
        async sendDeleteRequest() {
            this.deleteRequestRunning = true
            try {
                await this.$axios.delete(`/applications/exports/${this.deletingImage.deid}`)
                this.$store.dispatch('showSnackBar', {
                    snackBarText: `Successfully deleted ${this.deletingImage.app_name}.`,
                    snackBarTimeout: 5000,
                    snackBarIcon: 'check_circle'
                })
                this.$store.dispatch('snapshotStore/fetchExportedImages', { snid: this.$route.params.snid, query: this.imageListQuery })
            } catch (error) {
                LogRocket.captureException(error)
                this.$store.dispatch('showSnackBar', {
                    snackBarText: `There was an error while deleting the environment. Please try again later.`,
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
            this.deletingImage = null
        }
    },
    watch: {
        imageListQuery(to) {
            this.$store.dispatch('snapshotStore/fetchExportedImages', { snid: this.$route.params.snid, query: to })
        }
    }
}
</script>
